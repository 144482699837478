.module {
  width: 250px;
  margin: 0 0 1em 0;
  overflow: hidden;
}
.module p {
  margin: 0;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.cycle-multi-dropdown-filter {
  min-width: 200px;
  max-width: 600px;
  min-height: 35px;
}

.cycle-multi-dropdown-filter .p-multiselect-label.p-multiselect-items-label {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #e5e7eb;
  color: #4b5563;
  border-radius: 16px;
  font-size: small;
}

.select-heading {
  padding: 8px 0px 0px 0px;
  margin: 0;
}
