.archived-employee .p-datatable-scrollable-body {
  max-height: calc(100vh - 500px) !important;
}
.badge-les {
  color: orange;
}

.archived-emp{
  color: red;
}
.badge-usdh {
  color: dodgerblue;
}
.show-item {
  -webkit-keyframes: fadein .5s linear forwards;
  animation: fadein .5s linear forwards;
  padding: 1px;
}
