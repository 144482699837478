.image-container {
  border: solid rgb(206, 212, 218) 1px;
  border-radius: 4px;
  padding: 2px;
  width: 160px;
  height: 160px;
  margin-bottom: 11px !important;
}
.no-image {
  padding-top: 20px;
  color: rgb(206, 212, 218) ;
}
