.nav-link .form-group {
    margin-bottom: 0px;
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    width: 260px;
    height: 100%;
    position: absolute;
    z-index: 999;
    border-right: 1px solid #d2d2d2;
    /* background-color: #f0f0f0; */
    left: -260px;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 4px;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}

.sidebar-toggle-btn {
    position: absolute;
    z-index: 1000;
}