#errorPageDiv {
  position: relative;
  height: 70vh;
}

.error-page {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  width: 100%;
  line-height: 1.1;
}
