html {
  box-sizing: border-box;
  height: 100%;
}

.navbar {
  margin-top: -55px;
}

.navbar-brand {
  color: white !important;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid {
  padding: 15px;
}

footer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  background-color: lightgray;
  text-align: center;
  z-index: 900;
}

.page-wrapper {
  position: relative;
  min-height: calc(100vh - 212px);
}

.page-wrapper.toggled {
  margin-left: 260px;
}

/*----------------toggeled sidebar----------------*/
@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}

@media screen and (max-width: 767px) {
  .page-wrapper.toggled .page-content {
    padding-left: 0px;
  }

  .sidebar-wrapper {
    position: absolute;
    height: calc(100% - 110px);
    height: -webkit-calc(100% - 110px);
  }

  .page-wrapper.toggled {
    margin-left: 0px;
    left: 260px;
  }
}

body .p-menubar {
  border: 0px;
  color: white;
  background-color: transparent;
}

body .p-menubar .p-menuitem-link .p-menuitem-text {
  color: white;
}

.body .p-menubar .p-menuitem-link {
  color: white;
  display: block;
}

.menu-item-text {
  color: black;
}

/************* GTM **********


/******** Fonts ********/
/* oswald-500 - latin_latin-ext */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/oswald/oswald-v29-latin_latin-ext-500.svg#Oswald") format("svg");
  /* Legacy iOS */
}

/* roboto-regular - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-italic - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-italic.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-900 - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"),
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-900italic - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-v20-latin_latin-ext-900italic.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-mono-regular - latin_latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-regular.svg#RobotoMono") format("svg");
  /* Legacy iOS */
}

/* roboto-mono-italic - latin_latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Italic"), local("RobotoMono-Italic"),
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-italic.svg#RobotoMono") format("svg");
  /* Legacy iOS */
}

/* roboto-mono-700 - latin_latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Bold"), local("RobotoMono-Bold"),
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700.svg#RobotoMono") format("svg");
  /* Legacy iOS */
}

/* roboto-mono-700italic - latin_latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Bold Italic"), local("RobotoMono-BoldItalic"),
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.woff") format("woff"),
    /* Modern Browsers */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("./assets/fonts/roboto/roboto-mono-v7-latin_latin-ext-700italic.svg#RobotoMono") format("svg");
  /* Legacy iOS */
}

/************** GMT Typogrhaphy ********/

h2,
h3,
h4,
h5 {
  font-weight: 900;
}

h2,
.h2 {
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
}

h3,
.h3 {
  font-size: 1.9rem;
  margin-bottom: 1rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 900;
}

/******** Header ********/

.header-divider {
  border-top: solid 0.1875rem #d01319 !important;
  margin: 1rem 0 0;
  width: 100%;
}

.navbar-brand {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
}

header.bg-dark {
  position: relative;
  padding: 0 !important;
}

header.bg-dark .row,
header.bg-dark .col-12 {
  margin: 0;
  padding: 0;
}

header.bg-dark #brand {
  display: flex;
  align-items: center;
  margin: 0.75rem 0 0;
  padding: 0 0.75rem;
  box-sizing: border-box;
}

header.bg-dark #brand img.gtm-logo {
  vertical-align: middle;
  max-width: 12.5rem;
  height: auto;
  width: 100%;
  margin: 0 1rem 0 0;
  display: inline-block;
}

header.bg-dark #brand h1 {
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 0 0 1rem;
  /* margin     : 0; */
  vertical-align: middle;
}

header.bg-dark #brand a h1,
header.bg-dark #brand a:hover h1 {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

/******** Footer ********/
footer {
  background-color: #d6d7d9;
  position: relative;
  clear: both;
}

footer.row {
  margin: 0;
  padding: 1rem;
}

footer:after,
footer:before {
  clear: both;
}

footer h4 {
  font-size: 1.1rem;
  display: inline-block;
  vertical-align: top;
}

footer h4+p {
  margin-bottom: 0.5rem;
}

footer a {
  color: #212529;
}

footer a:hover {
  font-weight: 900;
}

footer p+p {
  margin-bottom: 0;
}

footer p a+a:before {
  content: "|";
  padding: 0 0.625rem;
}

footer img.footer-logo {
  max-width: 3rem;
  margin: 0 0.5rem 0.5rem 0;
  vertical-align: top;
}

@media screen and (max-width: 576px) {
  footer h4 {
    display: block;
  }
}

/*///////////////////////////////////////*/

.p-growl {
  position: absolute;
  width: 30em;
  cursor: pointer;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.5em 0.429em 0 0.429em;
}

body .p-multiselect .p-multiselect-trigger {
  background-color: transparent;
}

.debug {
  color: #888888;
  font-size: 12px;
}