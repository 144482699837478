@page {
  margin: 2%;
  font-size: 0.9rem;
}

.print-only {
  display: none; /* Hide on screen */
}

@media all {
  .printable-form .page-break {
    display: none;
  }
}

@media print {
  /* Correct page numbering */
  body {
    counter-reset: page;
    overflow: hidden;
  }

  .print-hide{
    display: none !important; /* Hide on print */
  }

  @page {
    margin: 1.95%;    
    /* size: auto; */
    font-size: 0.85rem;

    /* Set the page number at the bottom-right corner with smaller font size */
    @bottom-right {
      content: "Page " counter(page) " of " counter(pages);
      margin-right: 4.8%; /* Adjust right margin */     
      font-size: 10px; /* Adjust font size as needed */

    }

    @bottom-center {
      content: "SENSITIVE BUT UNCLASSIFIED";
      font-size: 10px; /* Adjust font size */
    }

    @bottom-left {
      margin-left: 4.8%; /* Adjust margin */
      font-size: 8px; /* Adjust font size */
      content: 'DS-0066 \A 12-2019'; /* Add a line break between the two contents */
      white-space: pre; /* Preserve the line break */
    }

    counter-increment: page;
  }

  /* Fix page-break class */
  .printable-form .page-break {
    display: block;
    page-break-before: always;
    margin-top: 1rem;
  }
}

.print-hide{
  display: block; /* Hide on print */
}

.printable-form {
  border-style: solid;
  border-width: 1.5px;
  font-family: 'Calibri', 'Arial', 'Helvetica', sans-serif;
}

.printable-form div {
  border-style: none;
  border-width: 0;
}

.printable-form div.border-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.printable-form div.border-dotted-b {
  border-bottom-style: dotted;
  border-bottom-width: 1px;
}

.printable-form div.border-r {
  border-right-style: solid;
  border-right-width: 1px;
}

.printable-form hr {
  border: 0;
  height: 2px;
  background: black;
}

.usseal {
  border: 0;
  max-height: 90px;
}

.page-break {
  page-break-before: always;
  page-break-after: always;
}

