.postnames {
  font-size: 120%;
}
.divposition {  
  position:absolute;
  top: 0px;
  bottom: 0px;
  }

  .btn-new-ceremony {
    margin-top: -40px !important;
    margin-bottom: 10px !important;
    float: right;
  }