$body-bg: white;
$body-color: #212529;

$font-family-sans-serif: "Roboto",
sans-serif;
$font-family-monospace: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;

$navbar-height: 55px;

$theme-colors: (
  "primary": #205493,
  "danger": #D01319,
  "success": #28A745,
  "secondary": #6C757D,
  "dark": #0A2240,
  "text": #212529,
  "white": #FFFFFF,
  "gold": #C1A783,
  "warning": #FFC107,
  "info": #17899B,
  "dark-red": #98181E,
  "light": #D6D7D9,
  "red": #D92120,
  "orange": #E6642C,
  "yellow":#D9AD3C,
  "yellow‑green":#B5BD4C,
  "green":#7FB972,
  "green‑blue":#117C8D,
  "light‑blue":#488BC2,
  "blue":#488BC2,
  "purple":#413B93,
  "magenta":#781C81,
  "light‑gray": #EFF0F1);

@import "~bootstrap/scss/bootstrap.scss";
