.p-button.p-datepicker-trigger {
  background: #ced4da !important;
  border: 1px solid #ced4da !important;
}

.rounded-border {
  border-radius: 4px;
}

.btn-new-cycle {
  /* margin-top: -40px !important; */
  margin-bottom: 10px !important;
  float: right;
}