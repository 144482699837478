/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.box-outer {
  position: relative;
  overflow: hidden;
}

.p-dropdown-panel {
  z-index: 1200 !important;
}
.p-button-link {
  background-color: transparent !important;
  border: none !important;
}

/* Mission Switcher Dropdown */
/* .mission-switcher.p-dropdown {
  background: transparent !important;
} */



/* Animation Tweeks */
.anim-transition {
  transition: all 300ms ease;
}

.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}

/* Hide sidebar when printing */
@media print {
  .no-print {
    margin-left: 0 !important;
  }
}

img.gtm-logo {
  max-width: 12.5rem;
  /* height: auto; */
  height: 5.1875rem;
  /* width: 100%; */
}

img.awards-logo {
  width: auto;
  height: 5.1875rem;
}

/* Accordion */
.p-accordion-header-text {
  width: 100%;
}
.p-accordion-header-link {
  text-decoration: none !important;
}
.p-accordion-content {
  background-color: #f1f1f1 !important;
  position: relative;
}
.p-accordion-tab {
  margin-bottom: 16px !important;
  box-shadow: 3px 3px 10px 0px rgba(188, 188, 188, 0.7);
}
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border: 1px solid #dee2e6 !important;
}

/* Tooltip */
.teal-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--teal-500) !important;
  border-bottom-color: var(--teal-500) !important;
}
.teal-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--teal-500);
}


/* Custom Utilities Classes */
.h-250px { height: 250px !important; }
.w-15 { width: 15% !important; }
.w-20 { width: 15% !important; }
.w-25 { width: 15% !important; }
.w-15 { width: 15% !important; }
.w-80 { width: 80% !important; }
.w-100px { width: 100px !important }
.w-240px { width: 240px !important }
.w-300px { width: 300px !important }
.w-400px { width: 400px !important }
.min-h-24px  { min-height: 24px !important; }
.min-h-50px  { min-height: 50px !important; }
.min-h-80px { min-height: 80px !important; }
.min-h-100px { min-height: 100px !important; }
.min-h-400px { min-height: 400px !important; }
.min-w-25px  { min-width: 25px !important; }
.min-w-80px  { min-width: 80px !important; }
.min-w-100px  { min-width: 100px !important; }
.min-w-150px { min-width: 150px !important; }
.min-w-240px { min-width: 240px !important; }
.min-w-300px { min-width: 300px !important; }
.max-h-70px { max-height: 70px !important; }
.max-h-80px { max-height: 80px !important; }
.max-h-100px { max-height: 100px !important; }
.max-h-120px { max-height: 120px !important; }
.max-h-360px { max-height: 360px !important; }
.max-h-500px { max-height: 500px !important; }
.max-h-90 { max-height: 90% !important; }
.max-w-150px { max-width: 150px !important; }
.max-w-500px { max-width: 500px !important; }
.max-w-60 { max-width: 60% !important; }

.overflow-y-scroll {
  overflow-y: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.modal-full-width { max-width: 95% !important; }
.no-padding { padding: 0 !important; }
.font-arial { font-family: Arial !important; }

.red-b { border: solid red 1px; }

.hr-gray {
  border-color: #aaa;
  border-width: 1px;
}

.modal-dialog {
  transform: translate(0,-50%) !important;
  top: 50% !important;
  margin: 0 auto !important;
}

.color-primary {
  color: #205493 !important;
}
.color-disabled {
  color: #cacaca !important;
}

.dropdown-item:active {
  color: #205493 !important;
  background-color: #fff !important;
  border-color: #205493 !important;
}
.color-text-warning {
  color: #c79d04;
}
.color-text-danger {
  color: #770505;
}

.font-line-through {
  text-decoration: line-through;
}

.all-awards .p-datatable-scrollable-body {
  max-height: calc(100vh - 600px) !important;
}

/* use same bootstrap colors to PrimeReact controls */
.p-button-primary, .p-button-primary.p-button-outlined:hover {
  color: #fff !important;
  background-color: #205493 !important;
  border-color: #205493 !important;
}
.p-button-primary.p-button-outlined {
  color: #205493 !important;
  background-color: #fff !important;
  border-color: #205493 !important;
}
.p-button-primary:hover {
  color: #fff !important;
  background-color: #194274 !important;
  border-color: #173c69 !important;
}
.p-button.p-button-xs {
  padding: 3px 6px;
  font-size: smaller;
}

.p-button-danger {
  color: #fff !important;
  background-color: #D01319 !important;
  border-color: #D01319 !important;
}

.p-button-danger-outlined {
  color: #205493 !important;
  background-color: #fff !important;  
  border: 2px solid #D01319 !important;
}
.p-button-danger:hover {
  color: #fff;
  background-color: #ad1015 !important;
  border-color: #a10f13 !important;
}
.p-component {
  font-family: Roboto !important;
}
.p-buttonset .p-button:not(:last-child):hover {
  border-right: 0 none !important;
}

.p-accordion.nomination-accordion-item .p-accordion-header .p-accordion-header-link {
  padding: 0.5em !important;
}



/* animation tweeks */
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
