.voting-text-values {
  overflow-y: auto;
  border-radius: 6px;
  border: 2px solid #d8d8d8;
  background-color: #fffdfd;
}

.details-row.show-comments {
  margin-right: 220px;
}
.details-row.loading {
  height: 200px;
  padding-top: 50px;
}

.vote-created-ago {
  font-size: 14px;
}

.signoff-toolbar > div:first-child > div {
  font-weight: bold;
}
.vote-details-justification {
  /* min-height: 360px; */
  height: calc(100vh - 560px);
}

.vote-details-comments {
  max-height: calc(100vh - 420px);
}

.scrollbar
{
	/*margin-left: 30px; */
	float: right;
	height: 100px;
	width: inherit;
	background: #fffdfd;
	overflow-y: scroll;
	margin-bottom: 25px;
}

#style-1::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(168, 162, 162, 0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 16px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 0px;
	box-shadow: inset 0 0 6px rgba(194, 189, 189, 0.3);
	background-color: #555;
}

.classWithPad { margin: 5px; padding: 5px; }

