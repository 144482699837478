.status-name {
  font-size: larger;
}

.search-employee-modal .p-datatable-scrollable-body {
  max-height: calc(100vh - 425px) !important;
}
.btn-edit-previous-position {
  margin-left: 3px;
  background-color: transparent;
  border: none;
  padding: 0px;
  border-radius: 4px;
  color: rgb(202, 199, 199);
  transition: transform 250ms;
}
.btn-edit-image {
  margin-left: 3px;
  background-color: transparent;
  border: none;
  padding: 0px;
  border-radius: 4px;
  color: rgb(202, 199, 199);
  transition: transform 250ms;
}
.previous-position {
  justify-content: flex-start;
  color: navy;
  text-indent: 30px;
  font-style: italic;
  font-size: 14px;
}
.aligned-rows td {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}


.right-sidebar {
  min-width: 200px;
  min-height: 400px;
  position: absolute;
}

.add-note-btn {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/* history and comments */
.history-and-comments {
  max-height: calc(100vh - 435px);
}

.small-text {
  font-size: smaller;
  margin: 4px 5px;
  color: gray;
}

.comment-block > div {
  font-size: smaller;
  margin: 5px 5px 0;
  padding: 4px 8px;
  border-radius: 3px;
  border: rgb(218, 213, 213) solid 1px;
  background-color: #fefefe;
}

.history-event {
  border-radius: 3px;
  border-right: #ccc solid 1px;
  border-bottom: #ccc solid 1px;
  padding: 2px 5px 0 2px;
  background-color: #f5f5f5;
  margin-bottom: 4px;
}

.mouse-over-on {
  cursor: pointer;
}
.mouse-clicked {
  margin-right: -2px;
  margin-top: 2px;
}

.btn-reason {
  height: 100px;
  width: 100px;
  margin: 5px;
  font-size: 13px !important;
}
.types-list {
  overflow-y: auto;
  height: 240px;
}
.criteria-text {
  overflow-y: auto;
  height: 250px;
  padding: 0 4px;
}
.nominee-line {
  min-height: 50px;
  height: auto;
  overflow: hidden;
  transition: all 0.3s ease;
}
.nominee-line.hide {
  min-height: 0;
  height: 0;
  opacity: 0;
  display: flex;
}
.nominee-modal-result {
  min-height: 300px !important;
}
.nominee-modal-result .p-datatable-wrapper {
  max-height: calc(100vh - 400px) !important;
}
.nominees-accordion .p-accordion-content {
  padding: 0 !important;
}
.nominees-accordion .p-accordion-tab {
  box-shadow: none !important;
}




.badge-les {
  color: orange;
}
.badge-usdh {
  color: dodgerblue;
}

.other-reason-input {
  height: 50px;
  opacity: 1;
  transition: opacity 300ms;
}
.other-reason-input.fade-in {
  height: 0;
  opacity: 0;
}

.hidden {
  visibility: hidden;
  display: none;
}

.cycle-dropdown-filter {
  min-width: 200px;
}
